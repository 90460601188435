<template>
  <main>
    <div class="wrap">
      <div class="h1">
        <h1>Professional Line</h1>
        <hr />
        <span></span>
      </div>
      <div class="content">
        <p>
          Продукты Профессиональной-линейки Recassist предлагают самое надежное решение для хранения данных, 
          разработанное и изготовленное в соответствии со всеми требованиями профессиональных пользователей. 
          Ассортимент продукции удовлетворяет потребности профессионалов и отвечает требованиям приложений в 
          области дублирования, мастеринга и корпоративного использования. CD-R Профессиональной-линейки - 
          это высококачественный профессиональный носитель, который обеспечивает емкость записи 700 МБ и скорость 
          записидо 52 раз. CD-R идеально подходит для резервного копирования данных, 
          а также для записи изображений и аудио. Носитель CD-R Profi-line сочетает в себе исключительную 
          производительность при отличной совместимости. Носитель CD-R Профессиональной-линейки Recassist - 
          это лицензированный Time Code TDK, изготовленный с использованием высококачественных японских 
          материалов и технологий, обеспечивающий наименьшее количество ошибок и обеспечивающий бесперебойную 
          работу с очень небольшим количеством брака, как ожидают профессионалы в области производства. 
          Компакт-диск Profi-line CD-R поставляется с широким отражающим слоем, оснащенным печатной поверхностью 
          для максимального покрытия художественных работ, доступен с большим разнообразием поверхностей для печати и упаковки.
        </p>
        <p>
          DVD-R Профессиональной-линейки Recassist 8x и 16x емкостью 4,7 ГБ - 
          это профессиональные носители высшего класса с лицензионным кодом идентификации носителей TDK (MID), 
          изготовленные в соответствии со строгими стандартами качества, предоставляя профессионалам лучший 
          продукт для записи на DVD. DVD-R Профессиональной-линейки обладает превосходной совместимостью и 
          стабильным качеством, предлагая один из самых надежных и долговечных DVD-R для профессионального использования с 
          гарантированным сроком службы 30 лет и поставляется с защитным слоем с твердым покрытием, который обеспечивает в 
          100 раз большую устойчивость к царапинам по сравнению с обычным DVD-R. DVD-R премиум-линейки FalconMedia 
          соответствует стандартам ROHS/REACH и ECMA 396 и 379.<br/><br/>
          Доступность поверхностей для печати:<br/>
          • Inkjet White or Silver Pearl coating, Smart Guard, Smart White, Smart Glossy, Water Repellent.<br/>
          • Thermal White or Silver.<br/>
          • Другое: Пустая блестящая и индивидуальная шелкография. <br/><br/>
          
          Основные преимущества:<br/>
          • Идентификационный код носителя TDK (MID). Гарантированный срок службы архива 30 лет.<br/>
          • Самый низкий уровень ошибок на рынке.<br/>
          • Доказанная высочайшая надежность и долговечность с полной отслеживаемостью.<br/>
          • Широкий выбор вариантов печати и упаковки.<br/>
          • Отличная производительность и совместимость с самыми популярными накопителями пациентах.<br/>
        </p>
      </div>
      <div class="disc-structure">
        <img src="/images/CD-R-PREMIUM-LINE-1024x346.jpg" alt="">
        <img src="/images/DVD-R-PREMIUM-LINE-1024x346.jpg" alt="">
      </div>
      <div class="data-sheet">
        <h2>СПЕЦИФИКАЦИЯ</h2>
        <table>
          <tbody>
            <tr>
              <th>DATA STORAGE SOLUTIONS FOR BUSINESS</th>
              <td>CD-r</td>
              <td>DVD "-" R<br/>
              DVD "+" R<br/>
              DVD DL "-" R</td>
            </tr>
            <tr>
              <th><b>DISC INFORMATION</b></th>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <th>Speed</th>
              <td>52x</td>
              <td>16x</td>
            </tr>
            <tr>
              <th>Capacity, MB</th>
              <td>700</td>
              <td>4700</td>
            </tr>
            <tr>
              <th>Media Code</th>
              <td>TDK</td>
              <td>TDK</td>
            </tr>
            <tr>
              <th>Time Code</th>
              <td>97m15s05f - 79m59s74f</td>
              <td>TDK TTH02 </td>
            </tr>
            <tr>
              <th>Safe Storage (Years)</th>
              <td>50</td>
              <td>50</td>
            </tr>
            <tr>
              <th>Estimated shelf life under office condition</th>
              <td>30 Years - ECMA 396</td>
              <td>30 Years - ECMA 396</td>
            </tr>
            <tr>
              <th></th>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <th>Phtalocianine Light Green Dye</th>
              <td>+</td>
              <td>+</td>
            </tr>
            <tr>
              <th></th>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <th><b>CERTIFICATIONS</b></th>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <th>Archival Line Certifitation</th>
              <td>+</td>
              <td>+</td>
            </tr>
            <tr>
              <th>LNE Certification</th>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <th>ROHS / REACH Compliant</th>
              <td>+</td>
              <td>+</td>
            </tr>
            <tr>
              <th>CE Sertification</th>
              <td></td>
              <td>+</td>
            </tr>
            <tr>
              <th>DICOM Compliant</th>
              <td></td>
              <td>+</td>
            </tr>
            <tr>
              <th>ECMA 366 & 379 Compliance</th>
              <td></td>
              <td>+</td>
            </tr>
            <tr>
              <th></th>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <th><b>PRINT OPTIONS</b></th>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <th>Smart Guard</th>
              <td>+</td>
              <td>+</td>
            </tr>
            <tr>
              <th>Smart White</th>
              <td>+</td>
              <td>+</td>
            </tr>
            <tr>
              <th>Inkjet Water Repelent</th>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <th>Inkjet White</th>
              <td>+</td>
              <td>+</td>
            </tr>
            <tr>
              <th>Inkjet Silver Pearl</th>
              <td>+</td>
              <td>+</td>
            </tr>
            <tr>
              <th>Smart Glossy</th>
              <td>+</td>
              <td>+</td>
            </tr>
            <tr>
              <th>Thermal White</th>
              <td>+</td>
              <td>+</td>
            </tr>
            <tr>
              <th>Thermal Silver</th>
              <td>+</td>
              <td>+</td>
            </tr>
            <tr>
              <th>Custom Printing</th>
              <td>+</td>
              <td>+</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </main>
</template>

<style lang="scss">
$pl: #2e31c7;
.disc-structure {
  text-align: center;
  margin-top: 30px;
}
.data-sheet {
  margin-top: 40px;
  h2 {
    text-align: center;
    font-size: 45px;
  }
  table {
    width: 100%;
    margin-top: 40px;
    
    tr {
    th {
      text-align: left;
      border-bottom: 1px solid grey;
      padding: 3px;  

      b{
        font-weight: bolder;
        font-size: 20px;
        color: $pl;
      }
    }
    
    td {
        width: 300px;
        border-bottom: 1px solid grey;
       border-left: 1px solid grey;
       padding: 3px;
       text-align: center;
      }      
    }
  }
}
// $ml: #52ab62;

main {
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: white;
}
.wrap {
  // background-color: white;
  max-width: 1280px;
  margin: 0 auto;
  .h1 {    
    h1{
      font-size: 45px;
      color: $pl;
    }
    hr {
      border: 6px solid $pl;
      background-color: $pl;
    }
  }
  .content {
    margin-top: 30px;
    p{
      line-height: 1.5em;
      font-size: 26px;
    }
  }
}
</style>

<script>
export default {
  name: "ProfiLineComponent",
  data() {
    return {
      rrer: "",
    };
  },
  // components: {
  //   ByeWorld
  // },
  methods: {
    iclick() {
      this.rrer = "";
    },
  },
};
</script>


